<template>
  <div
    v-if="hasAllInformation"
  >
    <div class="flex items-center justify-between pb-3">
      <div class="primary font-semibold">
        Payment Method
      </div>
      <button
        hidden
        class="button button--primary button--sm"
      >
        Change Payment Method
      </button>
    </div>
    <div class="custom-container">
      <div
        v-if="isCard"
        class="custom-table"
      >
        <div class="table-headers">
          <div>Card Number</div>
          <div>Expires</div>
          <div>Type</div>
          <div>Brand</div>
        </div>
        <div class="table-content primary">
          <div>{{ cardNumber }}</div>
          <div>{{ paymentDetails.expirationMonth }}/{{ paymentDetails.expirationYear }}</div>
          <div>{{ paymentDetails.type }}</div>
          <div class="flex items-center">
            <span class="pr-2">
              {{ capitalize(paymentDetails.brand) }}
            </span>
            <CardIcon :variant="cardVariant" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="table-headers">
          <div>Routing Number</div>
          <div>Account Number</div>
          <div>Account Type</div>
        </div>
        <div class="table-content primary">
          <div>{{ paymentDetails.routingNumber }}</div>
          <div>{{ paymentDetails.accountNumber }}</div>
          <div>{{ paymentDetails.accountType }}</div>
        </div>
      </div>
      <div
        v-if="hasAddressInfo"
        class="custom-table min-[1042px]:pl-16 flex-col"
      >
        <div class="custom-table">
          <div class="table-headers w-24">
            Address
          </div>
          <div class="table-content primary">
            <div>
              {{ paymentDetails.street }}
            </div>
            <div>
              {{ paymentDetails.region }}, {{ paymentDetails.zipCode }}
            </div>
          </div>
        </div>
        <div class="custom-table">
          <div class="table-headers w-24">
            Origin
          </div>
          <div class="table-content primary">
            {{ paymentDetails.country }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { capitalize } from 'lodash';
import CardIcon from '@/components/credit_card_icon/index.vue';

interface PaymentAddress {
  zipCode?: string;
  country?: string;
  street?: string | null;
  complement?: string | null;
  region?: string | null;
}

interface PaymentMethodCard extends PaymentAddress {
  type?: string;
  brand?: string;
  lastFourDigits?: string;
  expirationMonth?: string;
  expirationYear?: string;
}

interface PaymentMethodBank extends PaymentAddress {
  routingNumber?: string;
  accountNumber?: string;
  accountType?: string;
}

type PaymentDetails = PaymentMethodCard & PaymentMethodBank;

interface Props {
  paymentDetails: PaymentDetails;
}

const props = defineProps<Props>();
const isCard = ref(props.paymentDetails.type?.toLowerCase().includes('card'));
const cardNumber = ref(`*****${props.paymentDetails.lastFourDigits?.slice(-4)}`);
const cardVariant = ref<any>(props.paymentDetails.brand?.toLowerCase());
const hasAddressInfo = ref(
  [
    props.paymentDetails.street,
    props.paymentDetails.region,
    props.paymentDetails.country,
    props.paymentDetails.zipCode,
  ].every((v) => v),
);
const hasCardInformation = ref(
  [
    props.paymentDetails.lastFourDigits,
    props.paymentDetails.expirationMonth,
    props.paymentDetails.expirationYear,
    props.paymentDetails.type,
    props.paymentDetails.brand,
  ].every((v) => v),
);
const hasBankInformation = ref(
  [
    props.paymentDetails.routingNumber,
    props.paymentDetails.accountNumber,
    props.paymentDetails.accountType,
  ].every((v) => v),
);
const hasAllInformation = ref(hasCardInformation.value && (hasBankInformation.value || hasCardInformation.value));
</script>

<style scoped lang="scss">
.table-headers {
  @apply pr-4 text-sm text-gray-400;
}

.table-content {
  @apply text-sm font-semibold;
}
.custom-table {
  @apply flex;
}
.custom-table  div {
  @apply pb-1;
}

.custom-container {
  @apply flex min-[1042px]:flex-row flex-col;
}

</style>
