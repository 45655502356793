<template>
  <div v-if="data">
    <OrderSummary
      :orderSummary="data.orderSummary"
      class="pb-12"
      @refresh="loadOrderData"
    />
    <OrderTimeline
      :orderTimeline="data.orderTimeline"
      :orderStatus="data.orderSummary.status"
      class="pb-6"
    />
    <hr class="pb-6">
    <OrderDetails
      v-bind="data.orderSummary"
      class="pb-6"
    />
    <OrderPaymentMethod
      v-if="data.paymentDetails"
      :paymentDetails="{...data.paymentDetails, type: data.orderSummary.paymentMethod}"
      class="pb-6"
    />
    <OrderInstallments
      v-if="data.installments"
      :installments="data.installments.map((installment) => ({
        ...installment,
        currency: data?.orderSummary.currency ?? '',
        productName: data?.orderSummary.checkoutName ?? '',
      }))"
    />
  </div>
  <div v-else>
    <div class="flex items-center justify-center w-full h-full">
      <TbLoading />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onActivated } from 'vue';
import { useRoute } from 'vue-router';
import { TbLoading } from '@/components/tasty_bistro';
import { getOrder, GetOrderResponse } from '../api/get_order';
import OrderSummary from './components/order_summary.vue';
import OrderTimeline from './components/order_timeline.vue';
import OrderDetails from './components/order_details.vue';
import OrderPaymentMethod from './components/order_payment_method.vue';
import OrderInstallments from './components/order_installments.vue';

const route = useRoute();
const projectId = route.params.projectId as string;
const orderId = route.params.id as string;
const data = ref<GetOrderResponse>();

const loadOrderData = async () => {
  data.value = await getOrder({ projectId, orderId });
};

onActivated(async () => {
  await loadOrderData();
});

onBeforeMount(async () => {
  await loadOrderData();
});

</script>
