import http, { Http } from '@/lib/http';

interface PaymentAddress {
  zipCode?: string;
  country?: string;
  street?: string | null;
  complement?: string | null;
  region?: string | null;
}

interface PaymentMethodCard extends PaymentAddress {
  cvv: string;
  brand: string;
  lastFourDigits: string;
  expirationMonth: string;
  expirationYear: string;
}

interface PaymentMethodBank extends PaymentAddress {
  routingNumber: string;
  accountNumber: string;
  accountType: string;
}

type PaymentDetails = PaymentMethodCard | PaymentMethodBank;
interface OrderSummary {
  id: string;
  status: string;
  amount: string;
  totalPaid: string;
  currentBalance: string;
  currency: string;
  installments: number;
  paymentMethod: string;
  updatedAt: string;
  origin: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  checkoutName: string;
}

interface Installment {
  id: string;
  status: string;
  amount: string;
  date: string;
  type: string;
}

interface OrderTimeline {
  eventAt: string;
  eventType: string;
}

export interface GetOrderResponse {
  orderSummary: OrderSummary;
  installments: Installment[];
  paymentDetails?: PaymentDetails;
  orderTimeline: OrderTimeline[];
}

interface Params {
  projectId: string;
  orderId: string;
}

function getOrderCtor(http: Http) {
  return async ({ orderId, projectId }: Params): Promise<GetOrderResponse> => {
    const { data } = await http.get(`/projects/${projectId}/orders/${orderId}`, {
    });

    return data;
  };
}

export const getOrder = getOrderCtor(http);
