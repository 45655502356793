<template>
  <Table
    item-type="Payment"
    date-key="date"
    :items="installments"
    :total-item-count="installments.length"
    :headers="tableHeaders"
    :state="state"
    :count="{ singular: 'payment', plural: 'payments' }"
  >
    <template #row(id)="{ item }">
      <div class="truncate w-36">
        <routerLink :to="{ name: Pages.dashboardInHousePaymentsDrilldown, params: { id: item.id }}">
          <span>{{ item.id }}</span>
        </routerLink>
      </div>
    </template>

    <template #row(status)="{ item }">
      <Tooltip v-if="(item.status === 'Failed' || item.status === 'Past Due') && item.failureReason">
        <template #trigger="{ assignReference, show, hide }">
          <div
            :ref="(el: any) => assignReference(el)"
            @mouseenter="show"
            @mouseleave="hide"
          >
            <div
              class="inline-block w-2 h-2 mr-1 rounded-full"
              :class="paymentStatusToColorMap[item.status]"
            />
            {{ item.status }}
          </div>
        </template>
        <template #tooltip>
          {{ item.failureReason }}
        </template>
      </Tooltip>
      <div v-else>
        <div
          class="inline-block w-2 h-2 mr-1 rounded-full"
          :class="paymentStatusToColorMap[item.status]"
        />
        {{ item.status }}
      </div>
    </template>

    <template #row(amount)="{ item }">
      <span>{{ item.currency + item.amount }}</span>
    </template>

    <template #action="{ item }">
      <TbDrawer>
        <template
          v-if="selectedPaymentSchedule"
          #default="{ toggleDrawer }"
        >
          <Table
            :table-id="`payments-schedule-${item.id}`"
            item-type="Payment"
            date-key="dueDate"
            :items="selectedPaymentSchedule"
            :total-item-count="selectedPaymentSchedule?.length"
            :headers="paymentsScheduleTableHeaders"
            :state="paymentsScheduleState"
            flat
          />

          <button
            class="button button--secondary button--block"
            @click="toggleDrawer"
          >
            Close
          </button>
        </template>

        <template #toggle="{ toggleDrawer }">
          <button
            class="button button--block button--sm text-left"
            @click="toggleDrawer(); loadPaymentSchedule(item.id)"
          >
            Schedule
          </button>
        </template>
      </TbDrawer>
    </template>
  </Table>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { TbDrawer } from '@/components/tasty_bistro';

import Table from '@/components/table.vue';
import Tooltip from '@/components/tooltip.vue';
import { PageState } from '@/types';
import { formatDate } from '@/filters/date';
import { currencyInCents } from '@/filters/currency';
import { Pages } from '@/router/index';
import { paymentStatusToColorMap } from '@/helpers/category_color_mapper';
import { getPaymentsSchedule, Payment } from '../../api/get_payments_schedule';

const route = useRoute();
const projectId = route.params.projectId as string;

interface Installments {
  id: string;
  date: string;
  productName: string;
  type: string;
  amount: string;
  status: string;

}

interface Props {
  installments: Installments[];
}

defineProps<Props>();
const state = ref<PageState>(PageState.loaded);
const paymentsScheduleState = ref<PageState>(PageState.loaded);
const selectedPaymentSchedule = ref<Payment[] | null>(null);

const tableHeaders = [
  {
    title: 'Date',
    key: 'date',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Payment ID',
    key: 'id',
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Amount',
    key: 'amount',
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const paymentsScheduleTableHeaders = [
  {
    title: 'Due Date',
    key: 'dueDate',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Amount',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const loadPaymentSchedule = async (paymentId: string) => {
  selectedPaymentSchedule.value = null;

  try {
    paymentsScheduleState.value = PageState.loading;
    selectedPaymentSchedule.value = await getPaymentsSchedule({ id: paymentId, projectId });
    paymentsScheduleState.value = PageState.loaded;
  } catch (error) {
    paymentsScheduleState.value = PageState.error;
  }
};
</script>
