import { CurrencyCode } from '@/types';
import { ApplicationDetailsType } from '@/pages/app/project/types';
import http, { Http } from '@/lib/http';

export interface PaymentPlans {
  id: string;
  active: boolean;
  installments: number;
  downPaymentPercentage: number;
}

export interface Application {
  id: string;
  createdAt: Date;
  expiredAt: Date;
  origin: string;
  applicationResult: string;
  productName: string;
  productPriceInCents: number;
  currencyCode: CurrencyCode;
  loanOrderId: string;
  ppmPaymentPlans: PaymentPlans[];
  totalCount: number;
  details: {
    type: ApplicationDetailsType;
    description?: string;
  }
}

export interface Order {
  id: string;
  projectName: string;
  productName: string;
  amountInCents: number;
  purchaseDate: Date;
  currency: CurrencyCode;
  status: string;
  installmentCount: number;
  origin: string;
  feeAmountInCents: number;
  netAmountInCents: number;
  paidInstallments: number;
  paidAmountInCents: number;
  reverseCandidate: boolean;
  cardDetails?: {
    brand: string;
    lastFourDigits: string;
  };
  paymentMethod: string;
}

export interface Payment {
  id: string;
  orderId: string;
  amountInCents: number;
  status: string;
  date: Date;
}

export interface Student {
  createdAt: Date;
  studentId: string;
  studentEmail: string;
  studentPhoneNumber: string
  studentPhoneCountryCode: string
  studentName: string;
  studentAddressStreet: string;
  studentAddressRegion: string;
  studentAddressCountry: string;
  loanOrderIds: string[];
  status: string;
  applications: Application[];
  orders: Order[];
  installments: Payment[]
}

interface Data {
  id: string;
  projectId: string;
}

function getStudentCtor(http: Http) {
  return async function getStudents({ projectId, id }: Data): Promise<Student> {
    const { data } = await http.get(`/projects/${projectId}/students/${id}`);
    return data;
  };
}

export const getStudent = getStudentCtor(http);
