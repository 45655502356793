<template>
  <svg
    viewBox="0 0 38 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.07"
      d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
      fill="black"
    />
    <path
      d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
      fill="white"
    />
    <path
      d="M21.9347 8C20.0886 8 18.4389 8.90869 18.4389 10.5876C18.4389 12.5129 21.3648 12.6459 21.3648
      13.6131C21.3648 14.0204 20.8733 14.3849 20.0339 14.3849C18.8426 14.3849 17.9523 13.8755 17.9523
      13.8755L17.5713 15.5697C17.5713 15.5697 18.597 16 19.9587 16C21.9771 16 23.5653 15.0467 23.5653
      13.3391C23.5653 11.3046 20.6272 11.1756 20.6272 10.2779C20.6272 9.95883 21.0307 9.60926 21.8677
      9.60926C22.8121 9.60926 23.5826 9.97974 23.5826 9.97974L23.9555 8.34346C23.9555 8.34346 23.1171
      8 21.9347 8ZM5.0447 8.12349L5 8.37048C5 8.37048 5.77666 8.50546 6.47616 8.77472C7.37684 9.08349
      7.441 9.26323 7.59269 9.82151L9.24562 15.8726H11.4614L14.875 8.12349H12.6643L10.4709 13.3922L9.57581
      8.92619C9.49372 8.41506 9.07794 8.12349 8.56901 8.12349H5.0447ZM15.7639 8.12349L14.0297
      15.8726H16.1378L17.8659 8.12349H15.7639ZM27.5214 8.12349C27.0131 8.12349 26.7437 8.38194 26.5461
      8.83357L23.4576 15.8726H25.6683L26.096 14.6995H28.7893L29.0494 15.8726H31L29.2983 8.12349H27.5214ZM27.8089
      10.2171L28.4642 13.1249H26.7087L27.8089 10.2171Z"
      fill="#1434CB"
    />
  </svg>
</template>
