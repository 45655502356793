<template>
  <div class="card-icon">
    <Amex v-if="brand === CreditCardBrandEnum.Amex" />
    <Visa v-else-if="brand === CreditCardBrandEnum.Visa" />
    <Mastercard v-else-if="brand === CreditCardBrandEnum.Mastercard" />
    <Discover v-else-if="brand === CreditCardBrandEnum.Discover" />
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25
        0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25
        2.25 0 004.5 19.5z"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { CreditCardBrand, CreditCardBrandEnum } from '@/types';
import Visa from './visa.vue';
import Mastercard from './mastercard.vue';
import Discover from './discover.vue';
import Amex from './amex.vue';

type CreditCardBrands = CreditCardBrand | 'unknown';

interface Props {
  variant: CreditCardBrand | null;
}
const props = defineProps<Props>();
const brand = ref<CreditCardBrands>(props.variant ?? 'unknown');
</script>

<style lang="scss" scoped>
  .card-icon {
    height: 24px;
    flex-shrink: 0;
    & > svg {
      height: 100%;
    }
  }
</style>
