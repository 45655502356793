<template>
  <svg
    viewBox="0 0 38 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.07"
      d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
      fill="black"
    />
    <path
      d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
      fill="white"
    />
    <path
      d="M3.57 7.16001H2V12.66H3.57C4.4 12.66 5 12.46 5.53 12.03C6.16 11.51 6.53 10.73 6.53 9.92001C6.52
      8.29001 5.31 7.16001 3.57 7.16001ZM4.83 11.3C4.49 11.6 4.06 11.74 3.36 11.74H3.07V8.10001H3.36C4.05
      8.10001 4.47 8.22001 4.83 8.54001C5.2 8.87001 5.42 9.38001 5.42 9.91001C5.42 10.44 5.2 10.97 4.83
      11.3ZM7.02 7.16001H8.09V12.66H7.02V7.16001ZM10.71 9.27001C10.07 9.03001 9.88 8.87001 9.88 8.58001C9.88
      8.23001 10.22 7.97001 10.68 7.97001C11 7.97001 11.27 8.10001 11.54 8.42001L12.1 7.69001C11.64 7.29001
      11.09 7.08001 10.48 7.08001C9.51 7.08001 8.76 7.76001 8.76 8.66001C8.76 9.42001 9.11 9.81001 10.11
      10.17C10.53 10.32 10.74 10.42 10.85 10.48C11.06 10.62 11.17 10.82 11.17 11.05C11.17 11.5 10.82 11.83
      10.34 11.83C9.83 11.83 9.42 11.57 9.17 11.1L8.48 11.77C8.97 12.5 9.57 12.82 10.38 12.82C11.49 12.82
      12.28 12.08 12.28 11.01C12.3 10.12 11.93 9.72001 10.71 9.27001ZM12.63 9.92001C12.63 11.54 13.9 12.79
      15.53 12.79C15.99 12.79 16.39 12.7 16.87 12.47V11.21C16.44 11.64 16.06 11.81 15.58 11.81C14.5 11.81
      13.73 11.03 13.73 9.91001C13.73 8.85001 14.52 8.02001 15.53 8.02001C16.04 8.02001 16.43 8.20001 16.87
      8.64001V7.38001C16.4 7.14001 16.01 7.04001 15.55 7.04001C13.94 7.04001 12.63 8.32001 12.63 9.92001ZM25.39
      10.86L23.92 7.16001H22.75L25.08 12.8H25.66L28.03 7.16001H26.87L25.39 10.86ZM28.52
      12.66H31.56V11.73H29.59V10.25H31.49V9.32001H29.59V8.10001H31.56V7.16001H28.52V12.66ZM35.81 8.79001C35.81
      7.76001 35.1 7.17001 33.86 7.17001H32.27V12.67H33.34V10.46H33.48L34.96 12.67H36.28L34.55 10.35C35.36 10.18 35.81
      9.63001 35.81 8.79001ZM33.65 9.70001H33.34V8.03001H33.67C34.34 8.03001 34.7 8.31001 34.7 8.85001C34.7
      9.40001 34.34 9.70001 33.65 9.70001Z"
      fill="#231F20"
    />
    <path
      d="M20.16 12.86C20.9374 12.86 21.6829 12.5512 22.2325 12.0015C22.7822 11.4518 23.091 10.7063 23.091
      9.92899C23.091 9.15164 22.7822 8.40612 22.2325 7.85646C21.6829 7.30679 20.9374 6.99799 20.16 6.99799C19.3827
      6.99799 18.6371 7.30679 18.0875 7.85646C17.5378 8.40612 17.229 9.15164 17.229 9.92899C17.229 10.7063 17.5378
      11.4518 18.0875 12.0015C18.6371 12.5512 19.3827 12.86 20.16 12.86Z"
      fill="url(#paint0_linear_422_173)"
    />
    <path
      opacity="0.65"
      d="M20.16 12.86C20.9374 12.86 21.6829 12.5512 22.2325 12.0015C22.7822 11.4518 23.091 10.7063 23.091 9.92899C23.091
      9.15164 22.7822 8.40612 22.2325 7.85646C21.6829 7.30679 20.9374 6.99799 20.16 6.99799C19.3827 6.99799 18.6371
      7.30679 18.0875 7.85646C17.5378 8.40612 17.229 9.15164 17.229 9.92899C17.229 10.7063 17.5378 11.4518 18.0875
      12.0015C18.6371 12.5512 19.3827 12.86 20.16 12.86Z"
      fill="url(#paint1_linear_422_173)"
    />
    <path
      d="M36.57 7.50599C36.57 7.40599 36.5 7.35599 36.39 7.35599H36.23V7.83599H36.35V7.64599L36.49 7.83599H36.63L36.47
      7.63599C36.53 7.62599 36.57 7.57599 36.57 7.50599ZM36.37 7.57599H36.35V7.44599H36.37C36.43 7.44599 36.46 7.46599
      36.46 7.50599C36.46 7.55599 36.43 7.57599 36.37 7.57599Z"
      fill="#231F20"
    />
    <path
      d="M36.41 7.17599C36.18 7.17599 35.99 7.36599 35.99 7.59599C35.99 7.82599 36.18 8.01599 36.41 8.01599C36.64
      8.01599 36.83 7.82599 36.83 7.59599C36.83 7.36599 36.64 7.17599 36.41 7.17599ZM36.41 7.94599C36.23 7.94599 36.07
      7.79599 36.07 7.59599C36.07 7.40599 36.22 7.24599 36.41 7.24599C36.59 7.24599 36.74 7.40599 36.74 7.59599C36.74
      7.78599 36.59 7.94599 36.41 7.94599Z"
      fill="#231F20"
    />
    <path
      d="M37 12.984C37 12.984 27.09 19.873 8.97601 23H34.999C35.5267 23 36.033 22.7915 36.4076 22.4199C36.7822
      22.0483 36.9948 21.5437 36.999 21.016L37.023 17.996L37 12.985V12.984Z"
      fill="#F48120"
    />
    <defs>
      <linearGradient
        id="paint0_linear_422_173"
        x1="21.657"
        y1="12.275"
        x2="19.632"
        y2="9.10399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F89F20" />
        <stop
          offset="0.25"
          stop-color="#F79A20"
        />
        <stop
          offset="0.533"
          stop-color="#F68D20"
        />
        <stop
          offset="0.62"
          stop-color="#F58720"
        />
        <stop
          offset="0.723"
          stop-color="#F48120"
        />
        <stop
          offset="1"
          stop-color="#F37521"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_422_173"
        x1="21.338"
        y1="12.232"
        x2="18.378"
        y2="6.44599"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F58720" />
        <stop
          offset="0.359"
          stop-color="#E16F27"
        />
        <stop
          offset="0.703"
          stop-color="#D4602C"
        />
        <stop
          offset="0.982"
          stop-color="#D05B2E"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
