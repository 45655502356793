<template>
  <Page>
    <div>
      <h2 class="text-sm font-light text-gray-500">
        {{ route.params.id }}
      </h2>

      <h1 class="text-lg font-bold">
        {{ student.studentName }}
      </h1>
    </div>

    <div class="inline-flex flex-col px-4 my-2">
      <p v-if="student.studentPhoneNumber && student.studentPhoneCountryCode">
        {{ formatPhoneNumber(student.studentPhoneNumber, student.studentPhoneCountryCode) }}
      </p>

      <p v-else>
        No phone number
      </p>

      <p>
        {{ student.studentEmail }}
      </p>
    </div>

    <div class="px-4 mb-4">
      <p v-if="student.studentAddressStreet">
        {{ student.studentAddressStreet }}
        <br>
      </p>
      <p>
        <span v-if="student.studentAddressCity">{{ student.studentAddressCity }}, </span>
        <span v-if="student.studentAddressRegion">{{ student.studentAddressRegion }}, </span>
        <span v-if="student.studentAddressCountry">{{ student.studentAddressCountry }}, </span>
        <span v-if="student.studentAddressPostalCode">{{ student.studentAddressPostalCode }}</span>
      </p>
    </div>

    <div>
      <Table
        table-id="application-table"
        item-type="Application"
        date-key="createdAt"
        :items="student.applications"
        :count="{ singular: 'application', plural: 'applications' }"
        :state="state"
        :total-item-count="studentApplicationsTotalCount"
        :headers="applicationTableHeaders"
        :is-pagination-enabled="false"
      >
        <template #header(expiredAt)="{ value }">
          <div class="flex gap-2 items-center">
            <span>{{ value }}</span>
            <TbTooltip
              class="font-light"
              info="Each offer is valid until the shown expiry date, and don't worry,
              customers can easily go through our checkout again any time.
              We will not pull their credit again."
            />
          </div>
        </template>

        <template #row(origin)="{ value }">
          <OriginChip :title="value" />
        </template>

        <template #row(productPriceInCents)="{ item, value }">
          <span class="currency">{{ item.currencyCode + currencyInCents(value) }}</span>
        </template>

        <template #row(applicationResult)="{ value }">
          <div>
            <div
              class="inline-block w-2 h-2 mr-1 rounded-full"
              :class="orderApplicationResultToColorMap[value]"
            />
            {{ result(value) }}
          </div>
        </template>

        <template #row(applicationDetails)="{ item }">
          <ApplicationPlanControl
            :application="(item as Application)"
          />
        </template>
      </Table>
    </div>

    <div class="mt-4">
      <Table
        table-id="order-table"
        item-type="Order"
        date-key="purchaseDate"
        :items="student.orders"
        :state="state"
        :count="{ singular: 'order', plural: 'orders' }"
        :total-item-count="studentOrdersCount"
        :headers="ordersTableHeaders"
        :is-pagination-enabled="false"
      >
        <template #row(id)="{ value }">
          <!-- 2024-12-12 Lucas -->
          <!-- keeping this commented out for now since I believe that it's not the idea to let this be completely accessible yet -->
          <!-- There shouldn't be an issue of the page existing in it self but just limiting access to ourselves -->
          <!-- <routerLink :to="{ name: Pages.dashboardOrdersDrilldown, params: { projectId, id: value }}"> -->
          <span>{{ value }}</span>
          <!-- </routerLink> -->
        </template>

        <template #row(origin)="{ value }">
          <OriginChip :title="value" />
        </template>

        <template #row(amountInCents)="{ item }">
          <div class="flex flex-col">
            <span class="currency">{{ item.currency + currencyInCents(item.amountInCents) }}</span>
            <span
              v-if="item.origin === 'In-House'"
              class="text-xs text-gray-500"
            >{{ `${currencyInCents(item.paidAmountInCents)} Received` }}</span>
          </div>
        </template>

        <template #row(installmentCount)="{ value, item }">
          <div class="flex flex-col">
            <span>{{ value > 1 ? `${value} Months` : `${value} Month` }}</span>
            <span
              v-if="item.origin === 'In-House'"
              class="text-xs text-gray-500"
            >{{ item.paidInstallments || 0 }}/{{ value }} Paid</span>
          </div>
        </template>

        <template #row(status)="{ value}">
          <div>
            <div>
              <span
                class="inline-block w-2 h-2 mr-1 rounded-full"
                :class="orderStatusToColorMap[value]"
              />
              <span>{{ value }}</span>
            </div>
          </div>
        </template>

        <template #row(paymentMethod)="{ value, item }">
          <div
            class="flex items-center"
          >
            <CardIcon
              v-if="value==='card'"
              :variant="item.cardDetails?.brand"
            />
            <TbIcon
              v-if="value==='bank'"
              icon="bank"
            />
            <div
              class="pl-2"
            >
              {{ item.cardDetails?.lastFourDigits ? `****${item.cardDetails?.lastFourDigits}` : capitalize(value) }}
            </div>
          </div>
        </template>
      </Table>
    </div>

    <div
      v-if="student.installments?.length"
      class="mt-4"
    >
      <Table
        table-id="payment-table"
        item-type="Payment"
        date-key="date"
        :count="{ singular: 'payment', plural: 'payments' }"
        :items="student.installments"
        :state="state"
        :total-item-count="studentPaymentsCount"
        :headers="paymentsTableHeaders"
        :is-pagination-enabled="false"
      >
        <template #row(studentName)="{ item }">
          <routerLink :to="{ name: Pages.dashboardStudent, params: { id: item.studentId }}">
            <div class="flex flex-col">
              <span>{{ item.studentName }}</span>
              <span class="text-xs text-gray-500">
                {{ item.studentEmail }}
              </span>
            </div>
          </routerLink>
        </template>

        <template #row(id)="{ item }">
          <div class="truncate w-36">
            <routerLink :to="{ name: Pages.dashboardInHousePaymentsDrilldown, params: { id: item.id }}">
              <span>{{ item.id }}</span>
            </routerLink>
          </div>
        </template>

        <template #row(status)="{ value }">
          <div>
            <div
              class="inline-block w-2 h-2 mr-1 rounded-full"
              :class="paymentStatusToColorMap[value]"
            />
            {{ value }}
          </div>
        </template>

        <template #row(amountInCents)="{ item }">
          <span class="currency">{{ item.currency + currencyInCents(item.amountInCents) }}</span>
        </template>
      </Table>
    </div>
  </Page>
  <QuickStartGuideLink />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { capitalize } from 'lodash';
import Table from '@/components/table.vue';
import OriginChip from '@/components/origin_chip.vue';
import CardIcon from '@/components/credit_card_icon/index.vue';
import { PageState } from '@/types';
import { formatDate } from '@/filters/date';
import {
  TbTooltip, TbIcon,
} from '@/components/tasty_bistro';
import { currencyInCents } from '@/filters/currency';
import { result } from '@/filters/naming/order';
import { formatPhoneNumber } from '@/helpers/format_phone_number';
import { Pages } from '@/router/index';
import { orderApplicationResultToColorMap, paymentStatusToColorMap, orderStatusToColorMap } from '@/helpers/category_color_mapper';
import QuickStartGuideLink from '@/components/quick_start_guide_link.vue';
import ApplicationPlanControl from '@/pages/app/project/dashboard/applications/components/application_plan_control/index.vue';
import { getStudent, Application } from './api/get_student';
import Page from '../components/page.vue';

const state = ref(PageState.loaded);
const applicationTableHeaders = [
  {
    title: 'Application ID',
    key: 'id',
  },
  {
    title: 'Created On',
    key: 'createdAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Expires On',
    key: 'expiredAt',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Type',
    key: 'origin',
  },
  {
    title: 'Total',
    key: 'productPriceInCents',
  },
  {
    title: 'Result',
    key: 'applicationResult',
    formatter: result,
  },
  {
    title: 'Details',
    key: 'applicationDetails',
  },
];

const ordersTableHeaders = [
  {
    title: 'Date',
    key: 'purchaseDate',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Order ID',
    key: 'id',
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Payment',
    key: 'paymentMethod',
  },
  {
    title: 'Type',
    key: 'origin',
  },
  {
    title: 'Term',
    key: 'installmentCount',
  },
  {
    title: 'Total',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const paymentsTableHeaders = [
  {
    title: 'Date',
    key: 'dueDate',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Paid Date',
    key: 'paidDate',
    formatter: (val: string) => formatDate(val, 'MMM dd, yyyy'),
  },
  {
    title: 'Payment ID',
    key: 'id',
  },
  {
    title: 'Order ID',
    key: 'orderId',
  },
  {
    title: 'Checkout',
    key: 'productName',
  },
  {
    title: 'Amount',
    key: 'amountInCents',
    formatter: currencyInCents,
    class: 'currency',
  },
  {
    title: 'Status',
    key: 'status',
  },
];

const route = useRoute();
const projectId = route.params.projectId as string;

const student = ref<any>({});
const studentApplicationsTotalCount = ref(0);
const studentOrdersCount = ref(0);
const studentPaymentsCount = ref(0);

async function loadStudent() {
  const id = route.params.id as string;
  const data = await getStudent({ id, projectId });
  student.value = data;
  studentApplicationsTotalCount.value = data.applications && data.applications.length
    ? data.applications[0].totalCount : 0;
  studentOrdersCount.value = data.orders.length;
  studentPaymentsCount.value = data.installments.length;
}

onMounted(async () => {
  await loadStudent();
});
</script>
