import { AxiosError } from 'axios';
import http, { Http } from '@/lib/http';

type payOffScheduleData = {
  orderId: string;
  projectId: string;
  amountInCents?: number;
  installmentId?: string;
};

function payOffScheduleCtor(http: Http) {
  return async ({
    orderId,
    projectId,
    amountInCents,
    installmentId,
  }: payOffScheduleData): Promise<void> => {
    try {
      await http.post<void>(
        `/projects/${projectId}/off-schedule-payment/${orderId}/pay-off-schedule`,
        { amountInCents, installmentId, requestOrigin: 'merchant' },
      );
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };
}

export const payOffSchedule = payOffScheduleCtor(http);
