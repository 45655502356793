import { AxiosError } from 'axios';
import http, { Http } from '@/lib/http';
import { CreditCardBrand } from '@/types';

type GetOffSchedulePaymentOptionsData = {
  orderId: string;
  projectId: string;
};

export type Installment = {
  id: string;
  amountInCents: number;
  dueDate: string;
  status: string;
  currency: string;
};

export type OffSchedulePaymentOptions = {
  brand: CreditCardBrand | null;
  cardNumber: string | null;
  totalAmountInCents: number;
  currency: string;
  pendingInstallments: Installment[];
  lastName: string | null;
  firstName: string | null;
  email: string | null;
};

function getOffSchedulePaymentOptionsCtor(http: Http) {
  return async ({ orderId, projectId }: GetOffSchedulePaymentOptionsData): Promise<OffSchedulePaymentOptions> => {
    try {
      const { data } = await http.get<OffSchedulePaymentOptions>(
        `/projects/${projectId}/off-schedule-payment/${orderId}/options`,
      );
      return data;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };
}

export const getOffSchedulePaymentOptions = getOffSchedulePaymentOptionsCtor(http);
