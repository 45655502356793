<template>
  <Banner
    class="bg-primary-100"
    :show="showBanner"
  >
    <template #default>
      <div class="flex flex-row">
        <!-- left col -->
        <div class="flex flex-col grow">
          <h6 class="font-bold text-md">
            We're Back Online! 🎉
          </h6>
          <p>
            Our payment processor has resolved the issue, and transactions are
            now live again. You can start processing orders as usual. Thank you for your patience!
          </p>
        </div>

        <!-- right col -->
        <div class="flex flex-row gap-4 justify-center items-center">
          <button
            class="button button--primary button--sm"
            @click="closeBanner"
          >
            Dismiss
          </button>
        </div>
      </div>
    </template>
  </Banner>
</template>

<script lang="ts" setup>
import {
  onMounted, ref,
} from 'vue';
import { useStorage } from '@vueuse/core';
import Banner from '@/components/banner.vue';

const showBanner = ref(false);

const closeBanner = () => {
  showBanner.value = false;
  localStorage.setItem('ignoreBanner', 'true');
};

onMounted(() => {
  const storedValue = useStorage('ignoreBanner', false);

  if (!storedValue.value) {
    showBanner.value = true;
  }
});
</script>
