import App from '@/pages/app/index.vue';
import ProjectSelect from '@/pages/app/project_select/index.vue';
import Project from '@/pages/app/project/index.vue';
import Dashboard from '@/pages/app/project/dashboard/index.vue';
import DashboardHome from '@/pages/app/project/dashboard/home/index.vue';
import DashboardOrders from '@/pages/app/project/dashboard/orders/index.vue';
import DashboardOrdersDrilldown from '@/pages/app/project/dashboard/orders/drilldown/index.vue';
import DashboardStudents from '@/pages/app/project/dashboard/students/index.vue';
import DashboardStudent from '@/pages/app/project/dashboard/student/index.vue';
import DashboardInHousePayments from '@/pages/app/project/dashboard/in_house_payments/index.vue';
import DashboardInHousePaymentsDrilldown from '@/pages/app/project/dashboard/in_house_payments/drilldown/index.vue';
import DashboardSettlements from '@/pages/app/project/dashboard/settlements/index.vue';
import DashboardApplications from '@/pages/app/project/dashboard/applications/index.vue';
import DashboardSettings from '@/pages/app/project/dashboard/settings/index.vue';
import DashboardProfile from '@/pages/app/project/dashboard/settings/profile/index.vue';
import DashboardIntegrations from '@/pages/app/project/dashboard/settings/integrations/index.vue';
import ZapierDashboard from '@/pages/app/project/dashboard/settings/integrations/zapier/index.vue';
import StripeForm from '@/pages/app/project/dashboard/settings/integrations/stripe/index.vue';
import NmiForm from '@/pages/app/project/dashboard/settings/integrations/nmi/index.vue';
import AvalaraForm from '@/pages/app/project/dashboard/settings/integrations/sales_tax/avalara/index.vue';
import DashboardSettingsLogo from '@/pages/app/project/dashboard/settings/logo/index.vue';
import DashboardsNotificationsSubscribers from '@/pages/app/project/dashboard/settings/notifications_subscribers/index.vue';
import Demo from '@/pages/app/project/demo/index.vue';
import ScheduleDemo from '@/pages/app/project/demo/schedule_demo.vue';
import DemoScheduled from '@/pages/app/project/demo/demo_scheduled.vue';
import Onboarding from '@/pages/app/project//onboarding/index.vue';
import OnboardingProgress from '@/pages/app/project//onboarding/progress/index.vue';
import OnboardingSubmitted from '@/pages/app/project//onboarding/submitted/index.vue';
import OnboardingApproved from '@/pages/app/project//onboarding/approved/index.vue';
import OnboardingDeclined from '@/pages/app/project//onboarding/declined/index.vue';
import BusinessDetails from '@/pages/app/project/business_details/index.vue';
import BusinessDetailsReview from '@/pages/app/project/business_details/review/index.vue';
import BusinessName from '@/pages/app/project/business_details/business_name/index.vue';
import BusinessAddress from '@/pages/app/project/business_details/business_address/index.vue';
import ProductSelection from '@/pages/app/project/business_details/product_selection/index.vue';
import UploadLogo from '@/pages/app/project/upload_logo/index.vue';
import UserManagement from '@/pages/app/project/dashboard/settings/team/index.vue';
import PpmPaymentPlans from '@/pages/app/project/dashboard/settings/ppm_payment_plans/index.vue';
import BusinessOwner from '@/pages/app/project/business_owner/index.vue';
import BusinessOwnerForm from '@/pages/app/project/business_owner/form/index.vue';
import BusinessOwnerReview from '@/pages/app/project/business_owner/review/index.vue';
import PaymentDetails from '@/pages/app/project/payment_details/index.vue';
import PaymentDetailsForm from '@/pages/app/project/payment_details/form/index.vue';
import PaymentDetailsReview from '@/pages/app/project/payment_details/review/index.vue';
import Pricing from '@/pages/app/project/pricing/index.vue';
import PricingInfo from '@/pages/app/project/pricing/info.vue';
import PricingTerms from '@/pages/app/project/pricing/terms.vue';
import WelcomeVideo from '@/pages/app/project/onboarding/welcome/index.vue';
import QuickStartGuide from '@/pages/app/project/quick_start_guide/index.vue';

import Checkouts from '@/pages/app/project/dashboard/v2/checkouts/index.vue';
import Products from '@/pages/app/project/dashboard/v2/products/index.vue';
import PaymentSettings from '@/pages/app/project/dashboard/v2/payment_settings/index.vue';
import Invoices from '@/pages/app/project/dashboard/v2/invoices/index.vue';

import { applyMeta } from './helpers';

export enum Page {
  app = 'app',
  project = 'project',
  projectSelect = 'projectSelect',
  dashboard = 'dashboard',
  dashboardHome = 'dashboardHome',
  dashboardOrders = 'dashboardOrders',
  dashboardOrdersDrilldown = 'dashboardOrdersDrilldown',
  dashboardInHousePayments = 'dashboardInHousePayments',
  dashboardInHousePaymentsDrilldown = 'dashboardInHousePaymentsDrilldown',
  dashboardStudents = 'dashboardStudents',
  dashboardStudent = 'dashboardStudent',
  dashboardSettlements = 'dashboardSettlements',
  dashboardApplications = 'dashboardApplications',
  dashboardSettings = 'dashboardSettings',
  dashboardProfile = 'dashboardProfile',
  // <-- Integrations
  dashboardIntegrations = 'dashboardIntegrations',
  zapierDashboard = 'zapierDashboard',
  nmiForm = 'nmiForm',
  stripeForm = 'stripeForm',
  avalaraForm = 'avalaraForm',
  // Integrations -->
  dashboardSettingsLogo = 'dashboardSettingsLogo',
  ppmPaymentPlans = 'ppmPaymentPlans',
  dashboardNotificationsSubscribers = 'dashboardNotificationsSubscribers',
  demo = 'demo',
  scheduleDemo = 'scheduleDemo',
  demoScheduled = 'demoScheduled',
  onboarding = 'onboarding',
  welcomeVideo = 'welcomeVideo',
  onboardingProgress = 'onboardingProgress',
  onboardingSubmitted = 'onboardingSubmitted',
  onboardingApproved = 'onboardingApproved',
  onboardingDeclined = 'onboardingDeclined',
  businessDetails = 'businessDetails',
  businessDetailsReview = 'businessDetailsReview',
  businessDetailsProduct = 'businessDetailsProduct',
  businessDetailsName = 'businessDetailsName',
  businessDetailsAddress = 'businessDetailsAddress',
  uploadLogo = 'uploadLogo',
  paymentDetails = 'paymentDetails',
  paymentDetailsReview = 'paymentDetailsReview',
  paymentDetailsForm = 'paymentDetailsForm',
  businessOwner = 'businessOwner',
  businessOwnerReview = 'businessOwnerReview',
  businessOwnerForm = 'businessOwnerForm',
  pricing = 'pricing',
  pricingInfo = 'pricingInfo',
  pricingTerms = 'pricingTerms',
  integrations = 'integrations',
  checkouts = 'checkouts',
  products = 'products',
  paymentSettings = 'paymentSettings',
  quickStartGuide = 'quickStartGuide',
  userManagement = 'userManagement',
  invoices = 'invoices'
}

const routes = [
  {
    path: '',
    name: Page.app,
    component: App,
    children: [
      {
        path: 'select-project',
        name: Page.projectSelect,
        component: ProjectSelect,
      },
      {
        path: 'projects',
        name: Page.project,
        component: Project,
        children: [
          {
            path: ':projectId',
            component: Dashboard,
            name: Page.dashboard,
            children: [
              {
                path: '',
                name: Page.dashboardHome,
                component: DashboardHome,
              },
              {
                path: 'products/ppm-payment-plans',
                name: Page.ppmPaymentPlans,
                component: PpmPaymentPlans,
                meta: { requirePpm: true },
              },
              {
                path: 'checkouts',
                name: Page.checkouts,
                component: Checkouts,
              },
              {
                path: 'checkouts/products',
                name: Page.products,
                component: Products,
              },
              {
                path: 'checkouts/payment-settings',
                name: Page.paymentSettings,
                component: PaymentSettings,
              },
              {
                path: 'checkouts/invoicing',
                name: Page.invoices,
                component: Invoices,
              },
              {
                path: 'orders',
                name: Page.dashboardOrders,
                component: DashboardOrders,
              },
              {
                path: 'orders/:id',
                name: Page.dashboardOrdersDrilldown,
                component: DashboardOrdersDrilldown,
              },
              {
                path: 'orders/in-house-payments',
                name: Page.dashboardInHousePayments,
                component: DashboardInHousePayments,
                meta: { requirePpm: true },
              },
              {
                path: 'orders/in-house-payments/drilldown/:id',
                name: Page.dashboardInHousePaymentsDrilldown,
                component: DashboardInHousePaymentsDrilldown,
                meta: { requirePpm: true },
              },
              {
                path: 'students',
                name: Page.dashboardStudents,
                component: DashboardStudents,
              },
              {
                path: 'students/:id',
                name: Page.dashboardStudent,
                component: DashboardStudent,
              },
              {
                path: 'settlements',
                name: Page.dashboardSettlements,
                component: DashboardSettlements,
              },
              {
                path: 'applications',
                name: Page.dashboardApplications,
                component: DashboardApplications,
              },
              {
                path: 'settings',
                name: Page.dashboardSettings,
                component: DashboardSettings,
                children: [
                  {
                    path: 'profile',
                    name: Page.dashboardProfile,
                    component: DashboardProfile,
                  },
                  {
                    path: 'integrations',
                    name: Page.dashboardIntegrations,
                    component: DashboardIntegrations,
                  },

                  {
                    path: 'logo',
                    name: Page.dashboardSettingsLogo,
                    component: DashboardSettingsLogo,
                  },
                  {
                    path: 'notifications-subscribers',
                    name: Page.dashboardNotificationsSubscribers,
                    component: DashboardsNotificationsSubscribers,
                  },
                  {
                    path: 'user-management',
                    name: Page.userManagement,
                    component: UserManagement,
                  },
                ],
              },
              {
                path: 'integrations/zapier',
                name: Page.zapierDashboard,
                component: ZapierDashboard,
              },
              {
                path: 'integrations/stripe',
                name: Page.stripeForm,
                component: StripeForm,
              },
              {
                path: 'integrations/nmi',
                name: Page.nmiForm,
                component: NmiForm,
              },
              {
                path: 'integrations/sales-tax/avalara',
                name: Page.avalaraForm,
                component: AvalaraForm,
              },
              {
                path: 'quick-start-guide',
                name: Page.quickStartGuide,
                component: QuickStartGuide,
              },
            ],
          },
          {
            path: ':projectId/onboarding',
            component: Onboarding,
            name: Page.onboarding,
            children: [
              {
                path: 'progress',
                name: Page.onboardingProgress,
                component: OnboardingProgress,
              },
              {
                path: 'submitted',
                name: Page.onboardingSubmitted,
                component: OnboardingSubmitted,
              },
              {
                path: 'approved',
                name: Page.onboardingApproved,
                component: OnboardingApproved,
              },
              {
                path: 'declined',
                name: Page.onboardingDeclined,
                component: OnboardingDeclined,
              },
            ],
          },
          {
            path: ':projectId/business-details',
            name: Page.businessDetails,
            component: BusinessDetails,
            children: [
              {
                path: 'review',
                name: Page.businessDetailsReview,
                component: BusinessDetailsReview,
              },
              {
                path: 'select-product',
                name: Page.businessDetailsProduct,
                component: ProductSelection,
              },
              {
                path: 'name',
                name: Page.businessDetailsName,
                component: BusinessName,
              },
              {
                path: 'address',
                name: Page.businessDetailsAddress,
                component: BusinessAddress,
              },
            ],
          },
          {
            path: ':projectId/payment-details',
            name: Page.paymentDetails,
            component: PaymentDetails,
            children: [
              {
                path: '',
                name: Page.paymentDetailsForm,
                component: PaymentDetailsForm,
              },
              {
                path: 'review',
                name: Page.paymentDetailsReview,
                component: PaymentDetailsReview,
              },
            ],
          },
          {
            path: ':projectId/upload-logo',
            name: Page.uploadLogo,
            component: UploadLogo,
          },
          {
            path: ':projectId/business-owner',
            name: Page.businessOwner,
            component: BusinessOwner,
            children: [
              {
                path: '',
                name: Page.businessOwnerForm,
                component: BusinessOwnerForm,
              },
              {
                path: 'review',
                name: Page.businessOwnerReview,
                component: BusinessOwnerReview,
              },
            ],
          },
          {
            path: ':projectId/pricing',
            name: Page.pricing,
            component: Pricing,
            children: [
              {
                path: 'info',
                name: Page.pricingInfo,
                component: PricingInfo,
              },
              {
                path: 'terms',
                name: Page.pricingTerms,
                component: PricingTerms,
              },
            ],
          },
          {
            path: ':projectId/welcome',
            name: Page.welcomeVideo,
            component: WelcomeVideo,
          },
          {
            path: ':projectId/demo',
            name: Page.demo,
            component: Demo,
            children: [
              {
                path: 'book',
                name: Page.scheduleDemo,
                component: ScheduleDemo,
              },
              {
                path: 'booked',
                name: Page.demoScheduled,
                component: DemoScheduled,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const appRoutes = applyMeta(routes, { requireAuth: true });
