type SnakeToPascalCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Capitalize<T>}${Capitalize<SnakeToPascalCase<U>>}`
  : Capitalize<S>;

export type AsEnum<T extends string> = Record<SnakeToPascalCase<T>, T>;

export enum PageState {
  loading = 'loading',
  loaded = 'loaded',
  error = 'error'
}

export enum FormState {
  ready = 'ready',
  submitting = 'submitting',
  error = 'error',
  success = 'success',
}

export enum OrderOrigin {
  lnpl = 'lnpl',
  ppm = 'ppm'
}

export type OrderOriginTitle = 'Funding' | 'In-House';

export enum CurrencyCode {
  'USD' = 'USD',
  'CAD' = 'CAD',
}

export interface RoleControlConfig {
  hidden: boolean;
  restricted: boolean;
}

export interface RoleConfiguration {
  [role: string]: RoleControlConfig;
}
export interface RoleControlledActionConfiguration {
  [action: string]: RoleConfiguration;
}

export type CreditCardBrand = 'visa' | 'mastercard' | 'discover' | 'amex';
export const CreditCardBrandEnum: AsEnum<CreditCardBrand> = {
  Visa: 'visa',
  Mastercard: 'mastercard',
  Discover: 'discover',
  Amex: 'amex',
};
