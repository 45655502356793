<template>
  <div class="flex flex-col sm:flex-row sm:justify-between">
    <div>
      <div class="flex justify-between">
        <div class="primary text-sm font-semibold">
          Order
        </div>
      </div>
      <div class="flex items-center pb-1">
        <div class="text-2xl ">
          {{ orderSummary.currency }} {{ orderSummary.amount }}
        </div>
        <div class="pl-2">
          <div
            class="inline-block w-2 h-2 mr-1.5 rounded-full"
            :class="orderStatusToColorMap[orderSummary.status]"
          />{{ orderSummary.status }}
        </div>
      </div>
      <div
        class="flex items-center pb-3 text-sm"
        @click="copyOrderId(orderSummary.id)"
      >
        {{ orderSummary.id }} <TbCopyIcon class="w-4 h-4 ml-2" />
      </div>
      <div class="flex">
        <div class="pr-4 text-sm text-gray-400 ">
          <div>Last update</div>
          <div>Customer name</div>
          <div>Email address</div>
          <div>Payment method</div>
        </div>
        <div class="text-sm primary font-semibold">
          <div>{{ orderSummary.updatedAt }}</div>
          <div>{{ orderSummary.firstName }} {{ orderSummary.lastName }}</div>
          <div>{{ orderSummary.email }}</div>
          <div>{{ orderSummary.paymentMethod }}</div>
        </div>
      </div>
      <TbNotification
        :show="copyIdNotification"
        title="Success 🎉"
        :message="copyIdNotificationText"
        card-class="text-white bg-success"
        @close="copyIdNotification = false"
      />
    </div>
    <OffSchedulePayment
      v-if="hasOffSchedulePayment"
      class="mt-3 sm:mt-0"
      :order-id="orderSummary.id"
      :project-id="projectId"
      :role-name="roleName"
      :origin="orderSummary.origin"
      @refresh="emit('refresh')"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref, inject, Ref, computed,
} from 'vue';
import { useRoute } from 'vue-router';
import { orderStatusToColorMap } from '@/helpers/category_color_mapper';
import { useClipboard } from '@vueuse/core';
import { TbNotification, TbCopyIcon } from '@/components/tasty_bistro';
import { AppState } from '@/pages/app/api/get_app_state';
import OffSchedulePayment from './off_schedule_payment/index.vue';

const route = useRoute();
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;

const projectId = computed(() => route.params.projectId as string);
const features = computed(() => appState.value.projects[projectId.value].features);
const hasOffSchedulePayment = computed(() => features.value.offSchedulePaymentsEnabled);
const roleName = computed(() => appState.value.projects[projectId.value].currentUserRoleName);

interface OrderSummary {
  id: string;
  status: string;
  amount: string;
  currency: string;
  paymentMethod: string;
  updatedAt: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  origin: string;
}
interface Props {
  orderSummary: OrderSummary
}

defineProps<Props>();

const emit = defineEmits(['refresh']);

const copyIdNotification = ref(false);
const copyIdNotificationText = ref('');

const copyOrderId = (id: string) => {
  copyIdNotification.value = false;

  setTimeout(() => {
    const clipboard = useClipboard({ source: id });
    clipboard.copy();
    copyIdNotificationText.value = 'Order ID has been successfully copied to your clipboard';
    copyIdNotification.value = true;
  }, 200);
};
</script>
